import React from "react";

export const PageLoader = ({ text }) => {
  const loadingImg = "/assets/loader.svg";

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        zIndex: 9999,
        height: "100vh",
        width: "100%",
        gap: "4px",
      }}
    >
      <div className="loader" style={{ lineHeight: 0 }}>
        <img src={loadingImg} alt="Loading..." style={{ display: "block" }} />
      </div>
      {text && (
        <p
          style={{
            fontSize: "18px",
            color: "#000",
            fontWeight: "500",
            margin: 0,
          }}
        >
          {text}
        </p>
      )}
    </div>
  );
};

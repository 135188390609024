// CognitoProvider.js
import { Amplify, Auth, Hub } from "aws-amplify";
import Cookies from "js-cookie";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GetUserSubscription,
  RegisterUser,
  SubscribeFreeTrial,
  axiosInstance,
} from "./services/api";

// Add global error handling for debugging
window.onerror = function (message, source, lineno, colno, error) {
  console.error("Global error caught:", {
    message,
    source,
    lineno,
    colno,
    error,
  });
  return false;
};

// Handle unhandled promise rejections
window.addEventListener("unhandledrejection", function (event) {
  console.error("Unhandled promise rejection:", event.reason);
});

// Get current hostname to determine appropriate redirect
const hostname = window.location.hostname;
let redirectSignIn = "http://localhost:4000/login/callback";
let redirectSignOut = "http://localhost:4000/login";

// Set appropriate redirects based on environment
// Handle both localhost and 127.0.0.1 for local development
if (hostname === "localhost" || hostname === "127.0.0.1") {
  // Use the same hostname that the user is currently accessing the site with
  redirectSignIn = `http://${hostname}:4000/login/callback`;
  redirectSignOut = `http://${hostname}:4000/login`;
} else if (hostname === "developers.homesage.ai") {
  redirectSignIn = "https://developers.homesage.ai/login/callback";
  redirectSignOut = "https://developers.homesage.ai/login";
} else if (hostname === "developers.scaladom.com") {
  redirectSignIn = "https://developers.scaladom.com/login/callback";
  redirectSignOut = "https://developers.scaladom.com/login";
}

console.log("Current hostname:", hostname);
console.log("Redirect sign-in URL:", redirectSignIn);
console.log("Redirect sign-out URL:", redirectSignOut);

// Configure Amplify with your Cognito settings.
Amplify.configure({
  Auth: {
    identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    region: process.env.REACT_APP_REGION,
    identityPoolRegion: process.env.REACT_APP_IDENTITY_POOL_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      scope: [
        "phone",
        "email",
        "profile",
        "openid",
        "aws.cognito.signin.user.admin",
      ],
      redirectSignIn: redirectSignIn,
      redirectSignOut: redirectSignOut,
      responseType: "code",
    },
  },
});

// Updates cookies and axios default header based on the latest session.
const setToken = (session) => {
  const idToken = session.getIdToken().getJwtToken();
  const accessToken = session.getAccessToken().getJwtToken();
  const refreshToken = session.getRefreshToken().getToken();

  Cookies.set("id_token", idToken, {
    secure: true,
    sameSite: "strict",
  });
  Cookies.set("access_token", accessToken, {
    secure: true,
    sameSite: "strict",
  });

  // We no longer manually manage refresh_token expiry.
  localStorage.setItem("refresh_token", refreshToken);

  axiosInstance.defaults.headers.common.Authorization = idToken;
};

const removeToken = () => {
  Cookies.remove("id_token");
  Cookies.remove("access_token");
  localStorage.removeItem("refresh_token");
  delete axiosInstance.defaults.headers.common.Authorization;
};

const UserContext = createContext({});

export const CognitoProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [currentPlan, setCurrentPlan] = useState(null);
  const [userLoaded, setUserLoaded] = useState(false);
  const [subscribed, setSubscribed] = useState(false);
  const [loading, setLoading] = useState(false);

  const signIn = useCallback(async (payload) => {
    try {
      removeToken();
      setLoading(true);
      const cognitoUser = await Auth.signIn(payload);
      setToken(cognitoUser.signInUserSession);

      const current_url = window.location.href;
      const paramExists = current_url.includes("free-trial");

      if (paramExists) {
        try {
          await SubscribeFreeTrial();
          setTimeout(async () => {
            setUser(cognitoUser?.attributes);
            const response = await GetUserSubscription();
            setSubscribed(response.data.is_subscribed);
            setLoading(false);
            setUserLoaded(true);
            return cognitoUser;
          }, 2000);
        } catch (error) {
          setUser(cognitoUser?.attributes);
          setLoading(false);
          setUserLoaded(true);
          return cognitoUser;
        }
      } else {
        setUser(cognitoUser?.attributes);
        setLoading(false);
        setUserLoaded(true);
        return cognitoUser;
      }
    } catch (error) {
      setLoading(false);
      removeToken();
      setUser(null);
      throw Error(error);
    }
  }, []);

  const signUp = useCallback(
    async (payload) => {
      try {
        setLoading(true);
        const registeredUser = await Auth.signUp({
          ...payload,
          autoSignIn: {
            enabled: true,
          },
        });
        const current_url = window.location.href;
        const paramExists = current_url.includes("free-trial");
        if (paramExists) {
          await RegisterUser({
            email: payload.username,
            firstname: payload.firstname,
            lastname: payload.lastname,
            cognito_username: registeredUser.userSub,
            free_trial: true,
          });
        } else {
          await RegisterUser({
            email: payload.username,
            firstname: payload.firstname,
            lastname: payload.lastname,
            cognito_username: registeredUser.userSub,
          });
        }

        const user = await signIn({
          username: payload.username,
          password: payload.password,
        });
        setUserLoaded(true);
        setLoading(false);
        return user;
      } catch (error) {
        setLoading(false);
        throw Error(error);
      }
    },
    [signIn]
  );

  const signOut = useCallback(async () => {
    await Auth.signOut();
    removeToken();
    setUser(null);
    window.open(redirectSignOut, "_self");
  }, []);

  const changePassword = useCallback(async (payload) => {
    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.changePassword(
          user,
          payload.oldPassword,
          payload.newPassword
        );
      });
    } catch (error) {
      throw Error(error);
    }
  }, []);

  const UpdateUser = useCallback(async (payload) => {
    try {
      await Auth.currentAuthenticatedUser().then((user) => {
        return Auth.updateUserAttributes(user, payload);
      });
    } catch (error) {
      throw Error(error);
    }
  }, []);

  // getUser now relies solely on Amplify to refresh tokens when needed.
  const getUser = useCallback(async () => {
    let exists = false;
    try {
      setLoading(true);
      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession = await Auth.currentSession();
      setToken(currentSession);
      setUser(cognitoUser?.attributes);
      exists = true;
      setLoading(false);
    } catch (error) {
      console.error("Error in getUser:", error);
      removeToken();
      setLoading(false);
      setUser(null);
    } finally {
      if (!exists) setUserLoaded(true);
    }
  }, []);

  const getCognitoUser = useCallback(async () => {
    try {
      const cognitoUser = await Auth.currentAuthenticatedUser();
      return cognitoUser?.attributes;
    } catch (error) {
      throw Error(error);
    }
  }, []);

  const getUserSubscription = useCallback(async () => {
    try {
      setLoading(true);
      const response = await GetUserSubscription();
      setSubscribed(response.data.is_subscribed);
      setCurrentPlan(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setSubscribed(false);
    } finally {
      setUserLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (user?.email && Cookies.get("id_token")) getUserSubscription();
    getUser();
  }, [getUser, getUserSubscription, user?.email]);

  // Add Hub listener for social login auth events
  useEffect(() => {
    const handleAuthEvents = ({ payload }) => {
      console.log("Auth event:", payload.event);
      switch (payload.event) {
        case "signIn":
          console.log("User signed in via social provider");
          getUser();
          break;
        case "signOut":
          console.log("User signed out");
          setUser(null);
          removeToken();
          break;
        case "signIn_failure":
          console.error("Social login failed:", payload.data);
          break;
        default:
          break;
      }
    };

    Hub.listen("auth", handleAuthEvents);
    return () => Hub.remove("auth", handleAuthEvents);
  }, [getUser]);

  return (
    <UserContext.Provider
      value={{
        isAuthenticated: useMemo(() => !!user?.email, [user?.email]),
        isLoading: loading,
        isSubscribed: useMemo(() => !!subscribed, [subscribed]),
        userLoaded: useMemo(() => !!userLoaded, [userLoaded]),
        currentPlan,
        user,
        signUp,
        signIn,
        signOut,
        getUserSubscription,
        changePassword,
        UpdateUser,
        getUser,
        getCognitoUser,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useCognitoUser = () => useContext(UserContext);

import React, { createContext, useContext, useEffect, useState } from "react";
import { axiosInstance, GetUserProfile } from "../services/api.js";
import Cookies from "js-cookie";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Skip fetching user profile on public routes
    const path = window.location.pathname.toLowerCase();
    if (path.startsWith("/login") || path.startsWith("/signup")) {
      setLoading(false);
      return;
    }

    // Only fetch if token exists
    const token = Cookies.get("id_token");
    if (!token) {
      setLoading(false);
      return;
    }

    const fetchUserProfile = async () => {
      try {
        // Set token in headers
        axiosInstance.defaults.headers.common["Authorization"] = token;

        // Use the GetUserProfile function from api.js which uses the correct endpoint
        const response = await GetUserProfile();
        setUser(response.data);
        setError(null);
      } catch (err) {
        console.error("Error fetching user profile:", err);
        setError(err);

        // Check if it's an auth error and clear the invalid token
        if (
          err.response &&
          (err.response.status === 401 || err.response.status === 403)
        ) {
          console.warn("Auth token invalid, clearing token");
          Cookies.remove("id_token");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchUserProfile();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading, error }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

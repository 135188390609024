import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import Cookies from "js-cookie";
import { Auth } from "aws-amplify";
import createAuthRefreshInterceptor from "axios-auth-refresh";

const baseURL = process.env.REACT_APP_DEV_PORTAL_API_SERVER;
export const axiosInstance = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-Type": "application/json",
  },
});

// Set the authorization header when token is available
export const setAuthHeader = (token) => {
  if (token) {
    axiosInstance.defaults.headers.common["Authorization"] = token;
  } else {
    delete axiosInstance.defaults.headers.common["Authorization"];
  }
};

// Initialize auth header from cookie if available
const token = Cookies.get("id_token");
if (token) {
  setAuthHeader(token);
}

// Function to check if a token is about to expire and refresh it if needed
// JWT tokens have an 'exp' claim that indicates when they expire
export const ensureValidToken = async () => {
  try {
    const idToken = Cookies.get("id_token");

    if (!idToken) {
      console.log("No token found, redirect to login");
      window.location.href = "/login";
      return false;
    }

    // Parse the JWT token to get the expiration time
    const tokenParts = idToken.split(".");
    if (tokenParts.length !== 3) {
      console.error("Invalid token format");
      return false;
    }

    const payload = JSON.parse(atob(tokenParts[1]));
    const expTime = payload.exp * 1000; // Convert to milliseconds
    const currentTime = Date.now();
    console.log(
      `Token expiration (ms): ${expTime}, Current time (ms): ${currentTime}, Time until expiration (ms): ${
        expTime - currentTime
      }`
    );

    // If token is expired or about to expire in the next 10 minutes (600000 ms)
    const expirationBuffer = 10 * 60 * 1000; // 10 minutes in milliseconds - increased buffer for safety
    if (expTime - currentTime < expirationBuffer) {
      console.log(
        "Token is about to expire (within 10 minutes). Initiating refresh..."
      );

      // Get a fresh session from Cognito
      const currentSession = await Auth.currentSession();
      const newIdToken = currentSession.getIdToken().getJwtToken();

      // Update cookies and headers
      Cookies.set("id_token", newIdToken, {
        secure: true,
        sameSite: "strict",
      });

      axiosInstance.defaults.headers.common["Authorization"] = `${newIdToken}`;
      console.log(
        "Token refreshed successfully. New expiration time will be checked on next request."
      );

      // Force a small delay after token refresh to ensure synchronization
      await new Promise((resolve) => setTimeout(resolve, 100));
    } else {
      // console.log("Token is valid for more than 10 minutes.");
    }

    return true;
  } catch (error) {
    console.error("Error refreshing token:", error);
    if (error.code === "NotAuthorizedException") {
      // console.log("Authentication error - redirecting to login");
      // Clear tokens and redirect to login
      Cookies.remove("id_token");
      Cookies.remove("access_token");
      window.location.href = "/login";
    }
    return false;
  }
};

// Add request interceptor to ensure token is valid before each request
axiosInstance.interceptors.request.use(
  async (config) => {
    // Skip token validation for login/public endpoints
    const publicEndpoints = [
      "/users/register/",
      "/subscription/plans/",
      "/login",
      "/signup",
    ];

    const isPublicEndpoint = publicEndpoints.some((endpoint) =>
      config.url.includes(endpoint)
    );

    if (!isPublicEndpoint) {
      // Always ensure we have a valid token before making requests
      const tokenIsValid = await ensureValidToken();

      // If token validation failed and we're not redirecting already, force a small delay
      // This helps prevent race conditions with token refresh
      if (!tokenIsValid) {
        console.warn("Token validation failed, request may fail");
        await new Promise((resolve) => setTimeout(resolve, 100));
      } else if (
        config.url.includes("/searches/properties/") &&
        config.params?.sort_by
      ) {
        // Extra safeguard for sorted property searches which are causing 500 errors
        // console.log(
        //   "Property search with sorting detected, adding extra delay for token propagation"
        // );
        await new Promise((resolve) => setTimeout(resolve, 200));
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Define refreshAuthLogic for axios-auth-refresh
const refreshAuthLogic = (failedRequest) => {
  return Auth.currentSession()
    .then((currentSession) => {
      const newIdToken = currentSession.getIdToken().getJwtToken();
      // Update the cookie and axios default header
      Cookies.set("id_token", newIdToken, { secure: true, sameSite: "strict" });
      axiosInstance.defaults.headers.common["Authorization"] = `${newIdToken}`;
      // Also update the failed request's header so it can be retried
      failedRequest.response.config.headers["Authorization"] = `${newIdToken}`;
      return Promise.resolve();
    })
    .catch((error) => {
      // On refresh failure, clear tokens and redirect
      Cookies.remove("id_token");
      Cookies.remove("access_token");
      window.location.href = "/login";
      return Promise.reject(error);
    });
};

// Register axios-auth-refresh interceptor for status codes 401 and 403
createAuthRefreshInterceptor(axiosInstance, refreshAuthLogic, {
  statusCodes: [401, 403],
});

// Modify the existing response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // Suppress logging of 403 error for first attempt if handled by auth-refresh
    if (
      !(
        error.response &&
        error.response.status === 403 &&
        !originalRequest._retry
      )
    ) {
      console.error(
        "Response error:",
        error.response && error.response.status,
        error.response && error.response.data
      );
    }

    // Determine error status types
    const isAuthError =
      error.response?.status === 401 || error.response?.status === 403;
    const isServerError = error.response?.status === 500;

    // Special handling for 500 errors on property searches with sorting
    const isPropertySearchSortError =
      isServerError &&
      originalRequest.url.includes("/searches/properties/") &&
      (originalRequest.params?.sort_by ||
        originalRequest.url.includes("sort_by="));

    if (isPropertySearchSortError && !originalRequest._sort_retry) {
      originalRequest._sort_retry = true;
      console.log(
        "Detected 500 error on property search with sorting - likely token synchronization issue. Retrying with fresh token..."
      );
      try {
        // Force a token refresh for property search
        const currentSession = await Auth.currentSession();
        const newIdToken = currentSession.getIdToken().getJwtToken();
        Cookies.set("id_token", newIdToken, {
          secure: true,
          sameSite: "strict",
        });
        axiosInstance.defaults.headers.common[
          "Authorization"
        ] = `${newIdToken}`;
        originalRequest.headers["Authorization"] = `${newIdToken}`;
        // Wait additional time to ensure token propagation
        await new Promise((resolve) => setTimeout(resolve, 500));
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        console.error("Special token refresh failed:", refreshError);
        return Promise.reject(error);
      }
    }

    // Remove the manual general token refresh block to let axios-auth-refresh handle 401/403 errors
    return Promise.reject(error);
  }
);

export const GetEndpoints = async () => {
  return await axiosInstance.get(`/endpoints/`);
};

export const GetEndpointDetails = async (id) => {
  return await axiosInstance.get(`/endpoints/${id}`);
};

export const RegisterUser = async (data) => {
  const formData = new FormData();
  formData.append("email", data.email);
  formData.append("cognito_username", data.cognito_username);
  formData.append("firstname", data.firstname);
  formData.append("lastname", data.lastname);
  if (data.free_trial) {
    formData.append("free_trial", data.free_trial);
  }
  return await axiosInstance.post("/users/register/", formData);
};

export const GetUserSubscription = async () => {
  axiosInstance.defaults.headers.common["Authorization"] =
    Cookies.get("id_token");
  return await axiosInstance.get("/subscription/info/");
};

export const UpdateUserInfo = async (data) => {
  return await axiosInstance.put("/users/update/", data);
};

export const GetTrialInfo = async () => {
  axiosInstance.defaults.headers.common["Authorization"] = `${Cookies.get(
    "id_token"
  )}`;
  return await axiosInstance.get("/subscription/trial/info/");
};

export const AddPaymentMethod = async (url) => {
  return await axiosInstance.post(
    "/subscription/payment_method/add/?success_url=" +
      url +
      "&cancel_url=" +
      url
  );
};

export const GetDashboardData = async () => {
  axiosInstance.defaults.headers.common["Authorization"] = `${Cookies.get(
    "id_token"
  )}`;
  return await axiosInstance.get("/dashboard/data/");
};

export const SubscribeFreeTrial = async () => {
  return await axiosInstance.post("/subscription/free_trial/subscribe/");
};

export const GetSubscriptions = async () => {
  return await axiosInstance.get("/subscription/plans/");
};

export const UpdateSubscription = async (plan) => {
  return await axiosInstance.post(`/subscription/update/`, { plan_id: plan });
};

export const CheckoutPlan = (plan, promoCode = null) => {
  // Extract plan ID correctly whether plan is an object or direct ID
  const planId = typeof plan === "object" ? plan.id : plan;

  // console.log(
  //   `CheckoutPlan called with planId: ${planId}, promoCode: ${promoCode}`
  // );

  // Build the URL with proper parameters for coupon code
  const url = promoCode
    ? `/subscription/checkout/?plan_id=${planId}&promo_code=${encodeURIComponent(
        promoCode
      )}`
    : `/subscription/checkout/?plan_id=${planId}`;

  // Ensure token is included in the header
  return axiosInstance.get(url);
};

export const ManageBilling = async (url) => {
  try {
    // Ensure authorization header is set
    const idToken = Cookies.get("id_token");
    if (idToken) {
      axiosInstance.defaults.headers.common["Authorization"] = `${idToken}`;
    }

    // Ensure the URL is properly encoded
    const encodedUrl = encodeURIComponent(url);
    return await axiosInstance.get(
      `/subscription/manage/?return_url=${encodedUrl}`
    );
  } catch (error) {
    throw error;
  }
};

export const GetTemplates = async () => {
  return await axiosInstance.get("/templates/dashboard/");
};

export const GetKeys = async () => {
  return await axiosInstance.get(`/keys/`);
};

export const GenerateKey = async () => {
  return await axiosInstance.get(`/keys/generate/?name=${uuidv4()}`);
};

export const RevealKey = async (id) => {
  return await axiosInstance.get(`/keys/reveal/?key_id=${id}`);
};

export const DeleteKey = async (id) => {
  return await axiosInstance.get(`/keys/delete/?key_id=${id}`);
};

// Targeted Marketing List

export const GetTargetedMarketingListCount = async (query) => {
  const updatedQuery = { ...query };
  if (updatedQuery.dom !== undefined) {
    updatedQuery.dom = String(updatedQuery.dom);
  }
  return await axiosInstance.post("/targeted_marketing/count/", {
    query: updatedQuery,
  });
};

// Create a specialized version of SearchProperties that handles sorting requests more carefully
export const SearchPropertiesWithSorting = async (query) => {
  try {
    console.log(
      "Performing property search with sorting - using specialized handling"
    );

    // Always force a token refresh for sorted property searches
    try {
      // Get a fresh session from Cognito regardless of current token state
      const currentSession = await Auth.currentSession();
      const newIdToken = currentSession.getIdToken().getJwtToken();

      // Update cookies and headers
      Cookies.set("id_token", newIdToken, {
        secure: true,
        sameSite: "strict",
      });

      axiosInstance.defaults.headers.common["Authorization"] = `${newIdToken}`;
      console.log("Forced token refresh for sorted property search");

      // Significant delay to ensure token propagation across all systems
      await new Promise((resolve) => setTimeout(resolve, 800));
    } catch (refreshError) {
      console.error("Forced token refresh failed:", refreshError);
      // Continue anyway, as the token might still be valid
    }

    // Clone the query object to avoid modifying the original
    const safeQuery = { ...query };

    // Make the API request
    try {
      const response = await axiosInstance.get(`/searches/properties/`, {
        params: safeQuery,
      });
      return response;
    } catch (error) {
      // If we get a 500 error, try one more time with even more delay
      if (error.response?.status === 500) {
        console.log("Got 500 error on sorted search, retrying after delay...");
        await new Promise((resolve) => setTimeout(resolve, 1000));

        return await axiosInstance.get(`/searches/properties/`, {
          params: safeQuery,
        });
      }
      throw error;
    }
  } catch (error) {
    console.error("Error in specialized property search:", error);
    console.error("Request parameters:", query);
    if (error.response) {
      console.error("Error response status:", error.response.status);
      console.error("Error response data:", error.response.data);
    }
    throw error;
  }
};

// Update the original SearchProperties to use the specialized function when sorting is involved
export const SearchProperties = async (query) => {
  // console.log("Searching properties with query:", query);

  // Check if sorting is requested
  if (query.sort_by) {
    // console.log("Sorting detected, using specialized handling");
    return await SearchPropertiesWithSorting(query);
  }

  // No sorting, use standard endpoint
  return await axiosInstance.post("/searches/properties/", { query });
};

export const SaveTargetedMarketingList = async (data) => {
  return await axiosInstance.post(`/searches/saved/`, data);
};

export const GetSavedTargetedMarketingList = async (page) => {
  return await axiosInstance.get(`/searches/saved/?page=${page}`);
};
export const GetTargetedMarketingList = async (query) => {
  return await axiosInstance.get(`/searches/saved/`, {
    params: query,
  });
};

export const GetSingleTargetedMarketingList = async (id) => {
  return await axiosInstance.get(`/searches/saved/${id}`);
};

export const UpdateSingleSavedTargetedMarketingList = async (id, data) => {
  return await axiosInstance.put(`/searches/saved/${id}/`, data);
};

export const DeleteSingleTargetedMarketingList = async (id) => {
  return await axiosInstance.delete(`/searches/saved/${id}`);
};

// Get List
export const GetDownloadTargetedMarketingList = async (page) => {
  return await axiosInstance.get(
    `/searches/properties/downloads/?page=${page}`
  );
};
// Download
export const DownloadTargetedMarketingList = async (query) => {
  return await axiosInstance.get(`/searches/properties/download`, {
    params: query,
  });
};
// Update Name
export const UpdateDownloadTargetedMarketingList = async (data) => {
  return await axiosInstance.post(
    `/searches/properties/downloads/rename/`,
    data
  );
};

export const GetContacts = async (page) => {
  return await axiosInstance.get(`/crm/contacts/?page=${page}`);
};

export const AddContact = async (data) => {
  return await axiosInstance.post(`/crm/contacts/`, data);
};

export const UpdateContact = async (data) => {
  return await axiosInstance.put(`/crm/contacts/`, data);
};

export const RemoveContacts = async (selectedIDs) => {
  return await axiosInstance.delete(
    `/crm/contacts/?contact_ids=${selectedIDs}`
  );
};

export const GetContactDetails = async (id) => {
  return await axiosInstance.get(`/crm/contacts/${id}`);
};

export const SearchContacts = async (query, page) => {
  return await axiosInstance.get(
    `/crm/contacts/search/?keyword=${query}&page=${page}`
  );
};

export const AddToGroup = async (data, id) => {
  return await axiosInstance.post(`/crm/groups/${id}/contacts/add/`, data);
};

export const GetGroups = async (page) => {
  return await axiosInstance.get(`/crm/groups/?page=${page}`);
};

export const GetGroupsSimplified = async () => {
  return await axiosInstance.get(`/crm/groups/simplified/`);
};

export const GetGroupContacts = async (id, page) => {
  return await axiosInstance.get(`/crm/groups/${id}/contacts/?page=${page}`);
};

export const GetContactsNotInGroup = async (id, page) => {
  return await axiosInstance.get(
    `/crm/groups/${id}/contacts/notin/?page=${page}`
  );
};

export const SearchGroupContacts = async (id, query, page) => {
  return await axiosInstance.get(
    `/crm/groups/${id}/contacts/search/?keyword=${query}&page=${page}`
  );
};

export const SearchContactsNotInGroup = async (id, query, page) => {
  return await axiosInstance.get(
    `/crm/groups/${id}/contacts/notin/search/?keyword=${query}&page=${page}`
  );
};

export const AddGroup = async (data) => {
  return await axiosInstance.post(`/crm/groups/`, data);
};

export const UpdateGroup = async (data) => {
  return await axiosInstance.put(`/crm/groups/`, data);
};

export const DeleteGroup = async (groups) => {
  return await axiosInstance.delete(`/crm/groups/?group_ids=${groups}`);
};

export const RemoveGroupContacts = async (id, Data) => {
  return await axiosInstance.post(`/crm/groups/${id}/contacts/remove/`, Data);
};

export const GetGroupDetails = async (id) => {
  return await axiosInstance.get(`/crm/groups/${id}`);
};

export const GetAutomatedEmails = async (page) => {
  return await axiosInstance.get(`/automation/emails/?page=${page}`);
};

export const AddAutomatedEmail = async (data) => {
  return await axiosInstance.post(`/automation/emails/`, data);
};

export const GetAutomatedEmailDetails = async (id) => {
  return await axiosInstance.get(`/automation/emails/${id}`);
};

export const UpdateAutomatedEmail = async (data) => {
  return await axiosInstance.put(`/automation/emails/`, data);
};

export const RemoveAutomatedEmail = async (selectedIDs) => {
  return await axiosInstance.delete(
    `/automation/emails/?email_ids=` + selectedIDs
  );
};

export const GetSavedSearchesSimplified = async () => {
  return await axiosInstance.get(`/searches/saved/simplified/list/`);
};

export const UpdateAutomatedEmailStatus = async (id) => {
  return await axiosInstance.post(`/automation/emails/${id}/status/change/`);
};

export const GetAutomatedEmailGroups = async (id, page) => {
  return await axiosInstance.get(
    `/automation/emails/${id}/groups/?page=${page}`
  );
};
export const GetAutomatedEmailContacts = async (id, page) => {
  return await axiosInstance.get(
    `/automation/emails/${id}/contacts/?page=${page}`
  );
};

export const RemoveAutomatedEmailGroups = async (id, data) => {
  return await axiosInstance.post(
    `/automation/emails/${id}/groups/remove/`,
    data
  );
};
export const RemoveAutomatedEmailContacts = async (id, data) => {
  return await axiosInstance.post(
    `/automation/emails/${id}/contacts/remove/`,
    data
  );
};

export const AddAutomatedEmailGroups = async (id, data) => {
  return await axiosInstance.post(`/automation/emails/${id}/groups/add/`, data);
};
export const AddAutomatedEmailContacts = async (id, data) => {
  return await axiosInstance.post(
    `/automation/emails/${id}/contacts/add/`,
    data
  );
};

export const GetGroupsNotInAutomatedEmail = async (id, page) => {
  return await axiosInstance.get(
    `/automation/emails/${id}/groups/notin/?page=${page}`
  );
};
export const GetContactsNotInAutomatedEmail = async (id, page) => {
  return await axiosInstance.get(
    `/automation/emails/${id}/contacts/notin/?page=${page}`
  );
};

export const SearchContactsNotInAutomatedEmail = async (id, keyword, page) => {
  return await axiosInstance.get(
    `/automation/emails/${id}/contacts/notin/search/?keyword=${keyword}&page=${page}`
  );
};
export const SearchAutomatedEmailContacts = async (id, keyword, page) => {
  return await axiosInstance.get(
    `/automation/emails/${id}/contacts/search/?keyword=${keyword}&page=${page}`
  );
};

export const GetAutomatedEmailLogs = async (page) => {
  return await axiosInstance.get(`/automation/emails/logs/?page=${page}`);
};

export const GetFullReportsHistory = async (page) => {
  return await axiosInstance.get(`/report/?page=${page}`);
};

export const ReportBug = async (data) => {
  const formData = new FormData();
  if (data.description) formData.append("description", data.description);
  if (data.file) formData.append("attachment", data.file);

  return await axiosInstance.post(`/bugs/`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const GetWebsiteAuditReportData = async (website) => {
  return await axiosInstance.get(`templates/audit/report/?website=${website}`);
};

export const UpdatePropertyNote = async (propertyId, note) => {
  axiosInstance.defaults.headers.common["Authorization"] = `${Cookies.get(
    "id_token"
  )}`;
  return await axiosInstance.put(`/properties/saved/note/${propertyId}/`, {
    note,
  });
};

export const GetPropertyNote = async (propertyId) => {
  axiosInstance.defaults.headers.common["Authorization"] = `${Cookies.get(
    "id_token"
  )}`;
  return await axiosInstance.get(`/properties/saved/get-note/${propertyId}/`);
};

// Function to check out with a specific plan after signup
export const CheckoutWithPlan = async (planId) => {
  try {
    // Ensure we have a fresh token for the checkout operation
    await ensureValidToken();

    // console.log("Making checkout request for plan:", planId);

    // Get the token after ensuring it's valid
    const token = Cookies.get("id_token");
    if (token) {
      // console.log("Setting authorization header with token");
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }

    // Make the checkout request
    const response = await axiosInstance.get(
      `/subscription/checkout/?plan_id=${planId}`
    );

    // console.log("Checkout response:", response.data);

    return response;
  } catch (error) {
    console.error("Checkout error:", error);
    throw error;
  }
};

export const UpdateUserProfile = async (userData) => {
  try {
    // console.log("Updating user profile with data:", userData);

    // Get the token
    const token = Cookies.get("id_token");
    if (token) {
      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${token}`;
    }

    // Update profile
    const { data } = await axiosInstance.put(
      "/users/profile/update/",
      userData
    );

    // console.log("Profile update response:", data);

    return data;
  } catch (error) {
    console.error("Error updating profile:", error);
    throw error;
  }
};

export const GetUserProfile = async () => {
  return await axiosInstance.get("/users/profile/");
};

import React, { useEffect, useContext } from "react";
import { useCognitoUser } from "../../CognitoProvider";

// Create a context for Supademo
const SupademoContext = React.createContext();

export const useSupademo = () => useContext(SupademoContext);

export const SupademoProvider = ({ children }) => {
  const { user } = useCognitoUser();

  useEffect(() => {
    // Check if Supademo is available in the global scope
    if (window.Supademo) {
      // Initialize the Supademo SDK
      // Replace this ID with your actual Supademo ID
      window.Supademo(
        "066617fd995d54d14a55aa657526d3aabdcce0e624e1b6dc26afe7eb072546be",
        {
          variables: {
            email: user?.email || "", // Use user's email if available
            name: user?.name || "", // Use user's name if available
            // You can add more custom variables here as needed
          },
        }
      );
    } else {
      console.warn(
        "Supademo script not loaded. Make sure the script is included in the HTML."
      );
    }
  }, [user]); // Re-initialize when the user changes

  // The provider doesn't actually provide any value currently, but could in the future
  return (
    <SupademoContext.Provider value={{}}>{children}</SupademoContext.Provider>
  );
};

export default SupademoProvider;

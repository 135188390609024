import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// import "https://js.stripe.com/v3/pricing-table.js";
import "./index.css";
import "./styles/styles.css";
import { CognitoProvider } from "./CognitoProvider";
import { ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "./components/Toast";
import { UserProvider } from "./contexts/UserContext.js";
import theme from "./theme";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <CognitoProvider>
        <UserProvider>
          <App />
          <ToastContainer />
        </UserProvider>
      </CognitoProvider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { createContext, useState, useContext, useEffect } from "react";
import { axiosInstance } from "../services/api";
import Cookies from "js-cookie";
import { Toast } from "../components/Toast";

// Create context
const SavedPropertiesContext = createContext();

// Custom hook for using the context
export const useSavedProperties = () => {
  const context = useContext(SavedPropertiesContext);
  if (!context) {
    throw new Error(
      "useSavedProperties must be used within a SavedPropertiesProvider"
    );
  }
  return context;
};

// Provider component
export const SavedPropertiesProvider = ({ children }) => {
  const [savedProperties, setSavedProperties] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Fetch all saved properties on mount
  useEffect(() => {
    const fetchSavedStatus = async () => {
      try {
        const idToken = Cookies.get("id_token");
        if (!idToken) {
          setIsLoading(false);
          return;
        }

        // Get all saved properties
        const response = await axiosInstance.get("/properties/saved/");

        // Convert array to map for O(1) lookups
        const savedMap = {};
        if (response.data && Array.isArray(response.data)) {
          response.data.forEach((item) => {
            savedMap[item.property_id] = true;
          });
        }

        setSavedProperties(savedMap);
      } catch (error) {
        console.error("Error fetching saved properties:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchSavedStatus();
  }, []);

  // Function to check if a property is saved
  const isPropertySaved = (propertyId) => {
    return !!savedProperties[propertyId];
  };

  // Function to toggle saved status with optimistic updates
  const toggleSaved = async (propertyId, address) => {
    // Get current saved status
    const currentlySaved = isPropertySaved(propertyId);

    // Optimistically update UI
    setSavedProperties((prev) => ({
      ...prev,
      [propertyId]: !currentlySaved,
    }));

    try {
      // Then sync with server
      if (!currentlySaved) {
        // Save property
        await axiosInstance.post("/properties/save/", {
          property_id: propertyId,
          address,
        });
      } else {
        // Remove property
        await axiosInstance.delete(`/properties/saved/${propertyId}/`);
      }
    } catch (error) {
      // Revert on error
      setSavedProperties((prev) => ({
        ...prev,
        [propertyId]: currentlySaved,
      }));

      Toast.error(
        error?.response?.data?.detail || "Failed to update saved status"
      );
      console.error("Error toggling saved status:", error);
    }
  };

  // Function to refresh saved properties data
  const refreshSavedProperties = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get("/properties/saved/");

      const savedMap = {};
      if (response.data && Array.isArray(response.data)) {
        response.data.forEach((item) => {
          savedMap[item.property_id] = true;
        });
      }

      setSavedProperties(savedMap);
    } catch (error) {
      console.error("Error refreshing saved properties:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <SavedPropertiesContext.Provider
      value={{
        savedProperties,
        isLoading,
        isPropertySaved,
        toggleSaved,
        refreshSavedProperties,
      }}
    >
      {children}
    </SavedPropertiesContext.Provider>
  );
};

export default SavedPropertiesContext;

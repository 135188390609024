import { Toaster, toast } from 'react-hot-toast';
import { alpha } from '@mui/material';

// Custom toast component wrapper
export const Toast = {
  success: (message) => {
    toast.success(message, {
      style: {
        background: alpha('#4CAF50', 0.9),
        color: '#fff',
        fontSize: '14px',
        padding: '16px',
        borderRadius: '8px',
      },
      position: 'bottom-right',
      duration: 3000,
    });
  },
  error: (message) => {
    toast.error(message, {
      style: {
        background: alpha('#F44336', 0.9),
        color: '#fff',
        fontSize: '14px',
        padding: '16px',
        borderRadius: '8px',
      },
      position: 'bottom-right',
      duration: 4000,
    });
  },
  info: (message) => {
    toast(message, {
      style: {
        background: alpha('#2196F3', 0.9),
        color: '#fff',
        fontSize: '14px',
        padding: '16px',
        borderRadius: '8px',
      },
      position: 'bottom-right',
      duration: 3000,
    });
  },
  warning: (message) => {
    toast(message, {
      icon: '⚠️',
      style: {
        background: alpha('#FF9800', 0.9),
        color: '#fff',
        fontSize: '14px',
        padding: '16px',
        borderRadius: '8px',
      },
      position: 'bottom-right',
      duration: 4000,
    });
  },
  promise: async (promise, messages) => {
    return toast.promise(promise, {
      loading: messages.loading || 'Loading...',
      success: messages.success || 'Success!',
      error: messages.error || 'Error occurred.',
    }, {
      style: {
        fontSize: '14px',
        padding: '16px',
        borderRadius: '8px',
        background: alpha('#000', 0.8),
        color: '#fff',
      },
      position: 'bottom-right',
      success: {
        style: {
          background: alpha('#4CAF50', 0.9),
        },
      },
      error: {
        style: {
          background: alpha('#F44336', 0.9),
        },
      },
    });
  },
};

// ToastContainer component to be used at the root level
export const ToastContainer = () => (
  <Toaster
    position="bottom-right"
    reverseOrder={false}
    gutter={8}
    containerStyle={{
      bottom: 40,
      right: 40,
      zIndex: 9999,
    }}
    toastOptions={{
      className: '',
      duration: 3000,
      style: {
        background: '#363636',
        color: '#fff',
        fontSize: '14px',
        padding: '16px',
        borderRadius: '8px',
        maxWidth: '420px',
        boxShadow: '0 3px 10px rgba(0, 0, 0, 0.2)',
      },
    }}
  />
); 
import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { useCognitoUser } from "./CognitoProvider";
import { StripeProvider } from "./StripeProvider";
import SupademoProvider from "./components/supademo/SupademoProvider";
import AppRoutes from "./routes/AppRoutes";
import { ensureValidToken } from "./services/api";
import { SavedPropertiesProvider } from "./contexts/SavedPropertiesContext";

// Add session activity monitor
function useSessionMonitor() {
  useEffect(() => {
    let inactivityTimer;
    const activityEvents = [
      "mousedown",
      "mousemove",
      "keypress",
      "scroll",
      "touchstart",
    ];

    // Function to reset the inactivity timer
    const resetInactivityTimer = () => {
      clearTimeout(inactivityTimer);

      // Set new timer - check token validity after 5 minutes of inactivity
      inactivityTimer = setTimeout(async () => {
        console.log("User inactive for 5 minutes, checking token...");
        await ensureValidToken();
      }, 5 * 60 * 1000); // 5 minutes
    };

    // Initial setup
    resetInactivityTimer();

    // Add event listeners for user activity
    activityEvents.forEach((event) => {
      window.addEventListener(event, resetInactivityTimer);
    });

    // Setup periodic token checks regardless of activity (every 15 minutes)
    const periodicCheck = setInterval(async () => {
      console.log("Performing periodic token check...");
      await ensureValidToken();
    }, 15 * 60 * 1000); // 15 minutes

    // Cleanup
    return () => {
      clearTimeout(inactivityTimer);
      clearInterval(periodicCheck);
      activityEvents.forEach((event) => {
        window.removeEventListener(event, resetInactivityTimer);
      });
    };
  }, []);
}

function App() {
  const { isAuthenticated: IsCognitoUserAuthenticated } = useCognitoUser();

  // Use the session monitor
  useSessionMonitor();

  return (
    <div className="App">
      <header className="App-header">
        <BrowserRouter>
          <SupademoProvider>
            <StripeProvider>
              <SavedPropertiesProvider>
                <AppRoutes isAuthenticated={IsCognitoUserAuthenticated} />
              </SavedPropertiesProvider>
            </StripeProvider>
          </SupademoProvider>
        </BrowserRouter>
      </header>
    </div>
  );
}

export default App;

import React, { Suspense, lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { PageLoader } from "../components/page-loader";

// Lazy load all components
const UnSecureRoute = lazy(() => import("../components/cognito/UnSecureRoute"));
const CognitoRedirection = lazy(() =>
  import("../components/pages/signup/CognitoRedirection")
);
const CognitoSignUp = lazy(() =>
  import("../components/pages/signup/CognitoSignUp")
);
const SocialLoginCallback = lazy(() =>
  import("../components/pages/login/SocialLoginCallback")
);
const StripeProducts = lazy(() =>
  import("../components/stripe/StripeProducts")
);
const Sidebar = lazy(() => import("../components/sidebar"));
const SubscriptionPlans = lazy(() =>
  import("../components/pages/subscription/SubscriptionPlans")
);
const FullPropertyReport = lazy(() =>
  import("../components/pages/fullPropertyReport")
);
const Iframe = lazy(() => import("../components/pages/iframe"));
const PropertyReport = lazy(() => import("../components/pages/propertyReport"));
const TargetedLists = lazy(() => import("../components/pages/targetedLists"));
const DownloadedLists = lazy(() =>
  import("../components/pages/targetedLists/downloadedLists")
);
const SavedSearches = lazy(() =>
  import("../components/pages/targetedLists/savedSearches")
);
const Groups = lazy(() => import("../components/pages/targetedLists/groups"));
const APIDocumentation = lazy(() =>
  import("../components/pages/apis/APIDocumentation")
);
const APIs = lazy(() => import("../components/pages/apis"));
const Login = lazy(() => import("../components/pages/login/Login"));
const EditSavedSearch = lazy(() =>
  import("../components/pages/targetedLists/editSavedSearch")
);
const Keys = lazy(() => import("../components/pages/keys/Keys"));
const Dashboard = lazy(() => import("../components/pages/dashboard/Dashboard"));
const Propeties = lazy(() =>
  import("../components/pages/properties/properties")
);
const OlderPropeties = lazy(() =>
  import("../components/pages/properties/old_properties")
);
const Demo = lazy(() => import("../components/pages/fullPropertyReport/Demo"));
const AutomatedEmailLogs = lazy(() =>
  import("../components/pages/targetedLists/automatedEmailLogs")
);
const FullReportHistory = lazy(() =>
  import("../components/pages/fullPropertyReport/History")
);
const WebsiteAuditReport = lazy(() =>
  import("../components/pages/websiteAuditReport")
);
const ClientsAndGroups = lazy(() =>
  import("../components/pages/targetedLists/contacts/clientsAndGroups")
);
const AutomatedEmailTabs = lazy(() =>
  import("../components/pages/targetedLists/automatedEmails/automated_emails")
);
const PropertyPhotos = lazy(() =>
  import("../components/pages/properties/photos")
);
const SavedProperties = lazy(() =>
  import("../components/pages/savedProperties")
);
const SubscriptionDebug = lazy(() =>
  import("../components/pages/debug/SubscriptionDebug")
);

// Change from direct import to lazy load
const Settings = lazy(() => import("../components/pages/settings/Settings"));

const AppRoutes = ({ isAuthenticated }) => {
  return (
    <Suspense fallback={<PageLoader />}>
      <Routes>
        <Route
          path="/"
          element={
            !isAuthenticated ? (
              <Navigate replace to={"products"} />
            ) : (
              <Navigate replace to={"subscription"} />
            )
          }
        />

        {/* Auth */}
        <Route
          path="signup"
          element={
            <UnSecureRoute>
              <Suspense fallback={<PageLoader />}>
                <CognitoSignUp />
              </Suspense>
            </UnSecureRoute>
          }
        />
        <Route
          path="login"
          element={
            <UnSecureRoute>
              <Suspense fallback={<PageLoader />}>
                <Login />
              </Suspense>
            </UnSecureRoute>
          }
        />
        <Route
          path="login/redirection"
          element={
            <Suspense fallback={<PageLoader />}>
              <CognitoRedirection />
            </Suspense>
          }
        />
        <Route
          path="login/callback"
          element={
            <Suspense fallback={<PageLoader />}>
              <SocialLoginCallback />
            </Suspense>
          }
        />

        {/* Products */}
        <Route
          path="products"
          element={
            <Suspense fallback={<PageLoader />}>
              <Sidebar />
            </Suspense>
          }
        >
          <Route index element={<Navigate to={"lists"} replace />} />
          <Route
            path="lists"
            element={<Navigate to={"new-search"} replace />}
          />
          <Route
            path="lists/saved-searches"
            element={
              <Suspense fallback={<PageLoader />}>
                <SavedSearches />
              </Suspense>
            }
          />
          <Route
            path="lists/saved-searches/:id"
            element={
              <Suspense fallback={<PageLoader />}>
                <EditSavedSearch />
              </Suspense>
            }
          />
          <Route
            path="usage/download-history"
            element={
              <Suspense fallback={<PageLoader />}>
                <DownloadedLists />
              </Suspense>
            }
          />
          <Route
            path="lists/new-search"
            element={
              <Suspense fallback={<PageLoader />}>
                <TargetedLists />
              </Suspense>
            }
          />
          <Route
            path="lists/clients-groups"
            element={
              <Suspense fallback={<PageLoader />}>
                <ClientsAndGroups />
              </Suspense>
            }
          />
          <Route
            path="lists/groups"
            element={
              <Suspense fallback={<PageLoader />}>
                <Groups />
              </Suspense>
            }
          />
          <Route
            path="lists/automated-emails"
            element={
              <Suspense fallback={<PageLoader />}>
                <AutomatedEmailTabs />
              </Suspense>
            }
          />
          <Route
            path="lists/automated-emails/logs"
            element={
              <Suspense fallback={<PageLoader />}>
                <AutomatedEmailLogs />
              </Suspense>
            }
          />
          <Route
            path="saved-properties"
            element={
              <Suspense fallback={<PageLoader />}>
                <SavedProperties />
              </Suspense>
            }
          />
          <Route
            path="integrations/full-report"
            element={
              <Suspense fallback={<PageLoader />}>
                <FullPropertyReport />
              </Suspense>
            }
          />
          <Route
            path="full-property-report"
            element={<Navigate replace to={"demo"} />}
          />
          <Route
            path="full-property-report/demo"
            element={
              <Suspense fallback={<PageLoader />}>
                <Demo />
              </Suspense>
            }
          />
          <Route
            path="full-property-report/history"
            element={
              <Suspense fallback={<PageLoader />}>
                <FullReportHistory />
              </Suspense>
            }
          />
          <Route path="usage" element={<Navigate replace to={"dashboard"} />} />
          <Route
            path="usage/dashboard"
            element={
              <Suspense fallback={<PageLoader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="integrations"
            element={<Navigate replace to={"full-report"} />}
          />
          <Route
            path="integrations/keys"
            element={
              <Suspense fallback={<PageLoader />}>
                <Keys />
              </Suspense>
            }
          />
          <Route
            path="integrations/apis"
            element={
              <Suspense fallback={<PageLoader />}>
                <APIs />
              </Suspense>
            }
          />
          <Route
            path="integrations/apis/documentation/:id"
            element={
              <Suspense fallback={<PageLoader />}>
                <APIDocumentation />
              </Suspense>
            }
          />
          <Route
            path="properties/photos/:id"
            element={
              <Suspense fallback={<PageLoader />}>
                <PropertyPhotos />
              </Suspense>
            }
          />
        </Route>

        <Route
          path="settings"
          element={
            <Suspense fallback={<PageLoader />}>
              <Settings />
            </Suspense>
          }
        />
        <Route
          path="subscription"
          element={
            <Suspense fallback={<PageLoader />}>
              <SubscriptionPlans />
            </Suspense>
          }
        />

        {/* Iframe with Report */}
        <Route
          path="iframe"
          element={
            <Suspense fallback={<PageLoader />}>
              <Iframe />
            </Suspense>
          }
        />
        <Route
          path="report"
          element={
            <Suspense fallback={<PageLoader />}>
              <PropertyReport />
            </Suspense>
          }
        />
        <Route
          path="websites/audit/report"
          element={
            <Suspense fallback={<PageLoader />}>
              <WebsiteAuditReport />
            </Suspense>
          }
        />

        {/* Not used */}
        <Route
          path="properties"
          element={
            <Suspense fallback={<PageLoader />}>
              <Propeties />
            </Suspense>
          }
        />
        <Route
          path="properties/older"
          element={
            <Suspense fallback={<PageLoader />}>
              <OlderPropeties />
            </Suspense>
          }
        />

        <Route
          path="product-select"
          element={
            <Suspense fallback={<PageLoader />}>
              <StripeProducts />
            </Suspense>
          }
        />
        <Route
          path="settings"
          element={
            <Suspense fallback={<PageLoader />}>
              <Settings />
            </Suspense>
          }
        />
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
